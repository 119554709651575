.racing-phase_next {
  background: url('../assets/next-arrow.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 5vw;
  height: 10vw;
  position: absolute;
  top: calc(var(--vh, 1vh) * 3.5);
  z-index: 1;
  right: 1vw;
  mix-blend-mode: luminosity;
}

.racing-phase_prev {
  background: url('../assets/prev-arrow.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 5vw;
  height: 10vw;
  top: calc(var(--vh, 1vh) * 3.5);
  position: absolute;
  left: 1vw;
  z-index: 1;
  mix-blend-mode: luminosity;
}

.racing-phase_pagination {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 8);
  width: 100%;
}

.racing-phase_first {
  background-image: url('../assets/pagination-first.png');
  width: calc(var(--vh, 1vh) * 2);
  height: calc(var(--vh, 1vh) * 2);
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
  margin-right: calc(var(--vh, 1vh) * 0.5);
  margin-top: calc(var(--vh, 1vh) * -0.3);
}

.racing-phase_dot {
  margin-left: calc(var(--vh, 1vh) * 0.5);
  margin-right: calc(var(--vh, 1vh) * 0.5);
  background: white;
  border-radius: 50%;
  height: calc(var(--vh, 1vh) * 1.5);
  width: calc(var(--vh, 1vh) * 1.5);
  opacity: 0.5;
}

.racing-phase_dot-active {
  opacity: 1;
  transform: scale(1.5);
}
