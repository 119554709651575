.drafting-set_root {
  display: flex;
  position: relative;
  width: 100%;
  transition: filter 0.5s;
  align-items: center;
  justify-content: center;
}

.drafting-set_item-0 {
  animation: drafting-set_item-anim-0 0.5s forwards;
}

.drafting-set_item-1 {
  animation: drafting-set_item-anim-1 0.5s forwards;
  position: absolute;
}

@keyframes drafting-set_item-anim-0 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-4deg) translateX(-1.7vh);
  }
}

@keyframes drafting-set_item-anim-1 {
  0% {
    transform: rotate(0);
    margin-left: 0;
  }
  100% {
    transform: rotate(4deg) translateX(1.7vh);
  }
}
