.card-part_root {
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: flex-end;
  background-position: center;
  z-index: 1;
}

.card-part_sm {
  height: calc(var(--vh, 1vh) * 10);
}

.card-part_md {
  height: calc(var(--vh, 1vh) * 20);
}

.card-part_lg {
  height: calc(var(--vh, 1vh) * 30);
  width: calc(var(--vh, 1vh) * 30);
}

.card-part_shade {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  height: 10%;
  width: 100%;
  transform: skew(30deg);
  filter: blur(5px);
  margin-bottom: calc(var(--vh, 1vh) * -1);
}
