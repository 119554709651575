.card_root {
  position: relative;
  transition: 0.5s filter;
}

.card_root-disabled {
  filter: brightness(0.5);
}

/*
  card aspect ratio
  h: 1.41732283465
  w: 1
*/

.card_root-sm {
  width: calc(var(--vh, 1vh) * 9);
  height: calc(var(--vh, 1vh) * 12.7559055118);
  font-size: calc(var(--vh, 1vh) * 1);
}

.card_root-sm-md {
  width: calc(var(--vh, 1vh) * 10);
  height: calc(var(--vh, 1vh) * 14.173);
  font-size: calc(var(--vh, 1vh) * 1);
}

.card_root-md {
  width: calc(var(--vh, 1vh) * 12.7);
  height: calc(var(--vh, 1vh) * 18);
  font-size: calc(var(--vh, 1vh) * 1);
}

.card_root-md-lg {
  width: calc(var(--vh, 1vh) * 15);
  height: calc(var(--vh, 1vh) * 21.2598425198);
  font-size: calc(var(--vh, 1vh) * 1.7);
}

.card_root-lg {
  width: calc(var(--vh, 1vh) * 26.9291338583);
  height: calc(var(--vh, 1vh) * 30);
  font-size: calc(var(--vh, 1vh) * 2);
}

.card_root-xlg {
  width: calc(var(--vh, 1vh) * 30);
  height: calc(var(--vh, 1vh) * 42.5196850394);
  font-size: calc(var(--vh, 1vh) * 3);
}

.card_root-xxlg {
  width: calc(var(--vh, 1vh) * 40);
  height: calc(var(--vh, 1vh) * 56.692913386);
  font-size: calc(var(--vh, 1vh) * 4);
}

.card_highlight {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../assets/cards/highlight.png');
  top: 0;
}

.card_ribbon-title {
  text-align: center;
  position: absolute;
  top: 0.8%;
  width: 100%;
  color: black;
}

.card_power-container {
  display: flex;
  width: 51%;
  height: 5%;
  position: absolute;
  top: 10.2%;
  left: 28%;
}

.card_power-level-container {
  animation: card_power-level-enter 0.5s forwards;
  transform: scale(0);
  display: flex;
  height: 100%;
  width: 12%;
  margin-left: 5%;
}

.card_power-level {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../assets/cards/container/power-level-active.png');
  animation: card_power-level-shine infinite 7s;
}

@keyframes card_power-level-shine {
  15% {
    filter: brightness(100%);
    transform: scale(1) translateY(0);
  }

  20% {
    filter: brightness(130%);
    transform: scale(1.2) translateY(-15%);
  }
  25% {
    filter: brightness(100%);
    transform: scale(1) translateY(0);
  }
}

@keyframes card_power-level-enter {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.card_background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.card_border {
  margin-top: 9%;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 92%;
}

.card_power-txt {
  position: absolute;
  bottom: 12%;
  text-align: center;
  color: white;
  width: 80%;
  margin-left: 10%;
}

.card_positioned-text-left-attack {
  position: absolute;
  bottom: 2%;
  text-align: center;
  left: 0;
  color: white;
  font-size: 140%;
  width: 16%;
}

.card_positioned-text-left-life {
  position: absolute;
  bottom: 3%;
  text-align: center;
  left: 2%;
  color: white;
  font-size: 140%;
  width: 16%;
}
