.bid-panel_plus {
  background-image: url('../assets/bid/plus.png');
  width: calc(var(--vh, 1vh) * 8.8);
  height: calc(var(--vh, 1vh) * 8);
  background-repeat: no-repeat;
  background-size: contain;
}

.bid-panel_bid-btn-bottom {
  background-image: url('../assets/bid/bid-btn-bottom.png');
  width: calc(var(--vh, 1vh) * 15.5);
  height: calc(var(--vh, 1vh) * 15);
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  margin-left: calc(var(--vh, 1vh) * 1);
}

.bid-panel_bid-btn {
  margin-top: calc(var(--vh, 1vh) * -2);
  margin-left: calc(var(--vh, 1vh) * 0.65);
  width: calc(var(--vh, 1vh) * 13);
  height: calc(var(--vh, 1vh) * 13.1);
  background-size: contain;
  background-repeat: no-repeat;
}

.bid-panel_bid-btn-text {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--vh, 1vh) * 7);
  margin-top: calc(var(--vh, 1vh) * -0.2);
  margin-left: calc(var(--vh, 1vh) * -0.5);
}

.bid-panel_btn-bottom-txt {
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 0.5);
  left: calc(var(--vh, 1vh) * 4.7);
  font-weight: bold;
  font-size: calc(var(--vh, 1vh) * 2.5);
  letter-spacing: 0;
  color: #595959;
}

.bid-panel_minus {
  background-image: url('../assets/bid/minus.png');
  width: calc(var(--vh, 1vh) * 8.8);
  height: calc(var(--vh, 1vh) * 8);
  background-repeat: no-repeat;
  background-size: contain;
}

.bid-panel_btn-disabled {
  filter: brightness(50%);
}

.bid-panel_btn-shine {
  animation: bid-panel_btn-shine 1s ease-in;
  transition: 0.3s;
}

.bid-panel_btn-shine:active {
  transform: scale(0.9);
  filter: brightness(110%);
}

@keyframes bid-panel_btn-shine {
  0% {
    filter: brightness(50%);
  }
  50% {
    filter: brightness(115%);
  }
}
