.navigation-item_root {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/tab/background.png');
  background-size: cover;
  transition: 0.5s;
  height: calc(var(--vh, 1vh) * 8);
}

.navigation-item_root-inactive {
  filter: brightness(50%);
}

.navigation-item_icon {
  background-size: contain;
  background-repeat: no-repeat;
  height: calc(var(--vh, 1vh) * 7);
  width: calc(var(--vh, 1vh) * 7);
  transition: 0.5s;
}

.navigation-item_icon-active {
  transform: translateY(calc(var(--vh, 1vh) * -1.5));
  height: calc(var(--vh, 1vh) * 8);
  width: calc(var(--vh, 1vh) * 8);
}
