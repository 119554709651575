.betting-phase_root {
  height: calc(var(--vh, 1vh) * 98);
}

.betting-phase_header {
  margin-top: calc(var(--vh, 1vh) * 2.5);
  display: flex;
  justify-content: flex-end;
  padding-right: calc(var(--vh, 1vh) * 3);
  color: white;
  font-size: 4vh;
  margin-bottom: calc(var(--vh, 1vh) * -0.5);
}

.betting-phase_header-medal-gold {
  background-image: url('../assets/bet/medalGold.png');
  width: calc(var(--vh, 1vh) * 7);
  height: calc(var(--vh, 1vh) * 4.7);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  font-size: calc(var(--vh, 1vh) * 3);
  padding-top: calc(var(--vh, 1vh) * 1);
  margin-top: calc(var(--vh, 1vh) * -1);
}

.betting-phase_header-medal-bronze {
  margin-left: calc(var(--vh, 1vh) * 0.5);
  background-image: url('../assets/bet/medalBronze.png');
  width: calc(var(--vh, 1vh) * 6.5);
  height: calc(var(--vh, 1vh) * 5.5);
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  display: flex;
  margin-top: calc(var(--vh, 1vh) * -1);
  font-size: calc(var(--vh, 1vh) * 3);
  padding-top: calc(var(--vh, 1vh) * 1);
}

.betting-phase_btn {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 12);
  width: 100%;
}
