.app_root {
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;
  width: 100vw;
  background-image: url(./assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  color: white;
  font-size: 5vh;
  flex-direction: column;
}

input {
  user-select: auto;
}

.app_yard-with-display-input {
  border: none;
  border-bottom: 1px solid white;
  background: transparent;
  font-size: calc(var(--vh, 1vh) * 4);
  color: white;
  width: 50vw;
  outline: none;
}

.app_invalid-yard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.app_invalid-yard-input {
  height: calc(var(--vh, 1vh) * 8);
  width: 50vw;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  border-radius: calc(var(--vh, 1) * 1);
  color: white;
  font-size: calc(var(--vh, 1vh) * 4);
  text-align: center;
  box-shadow: inset 4px 4px 11px black;
}

.app_invalid-yard-btn {
  margin-bottom: calc(var(--vh, 1vh) * 10);
}

.app_invalid-yard-title {
  margin-top: calc(var(--vh, 1vh) * 10);
}

.app_yard-and-display-pen {
  background-image: url(./assets/editPen.png);
  background-repeat: no-repeat;
  margin-left: 10vw;
  width: calc(var(--vh, 1vh) * 4);
  height: calc(var(--vh, 1vh) * 3);
  background-size: contain;
  mix-blend-mode: difference;
}

.app_yard-and-display-edit {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: calc(var(--vh, 1vh) * 2);
}

.app_yard-and-display-tutorial-btn {
  display: flex;
  align-items: center;
  font-size: 3vh;
}

.app_yard-and-display-tutorial-check-container {
  width: calc(var(--vh, 1vh) * 3);
  height: calc(var(--vh, 1vh) * 3);
  background: rgba(0, 0, 0, 0.5);
  border-radius: calc(var(--vh, 1vh) * 0.4);
  position: relative;
  margin-right: calc(var(--vh, 1vh) * 0.5);
}

.app_yard-and-display-tutorial-check {
  background-image: url(./assets/check.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: calc(var(--vh, 1vh) * -1);
  transition: 0.3s;
  width: calc(var(--vh, 1vh) * 4);
  height: calc(var(--vh, 1vh) * 5);
}

.app_yard-and-display-tutorial-check-on {
  opacity: 1;
}

.app_yard-and-display-tutorial-check-off {
  opacity: 0;
}

.app_burger-menu {
  background-image: url(./assets/burgerMenu.png);
  position: absolute;
  right: 5%;
  top: 5.2%;
  width: calc(var(--vh, 1vh) * 4);
  height: calc(var(--vh, 1vh) * 4);
  mix-blend-mode: luminosity;
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
}

.app_burger-menu-root {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.app_burger-menu-root-closed {
  pointer-events: none;
}

.app_burger-menu-bg {
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  width: 100%;
  height: 100%;
  position: absolute;
}

.app_burger-menu-bg-closed {
  opacity: 0;
  pointer-events: none;
}

.app_burger-menu-container {
  background-image: url(./assets/burgerMenuContainer.png);
  position: absolute;
  width: calc(var(--vh, 1vh) * 34.6507352941);
  height: calc(var(--vh, 1vh) * 100);
  background-size: contain;
  background-repeat: no-repeat;
  right: calc(var(--vh, 1vh) * -34.6507352941);
  transition: right 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app_burger-menu-container-open {
  right: 0;
}

.app_burger-menu-item {
  margin-top: calc(var(--vh, 1vh) * 10);
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: calc(var(--vh, 1vh) * 7);
}

.app_burger-menu-item-fullscreen {
  background-image: url(./assets/fullscreen.png);
  width: calc(var(--vh, 1vh) * 6);
  height: calc(var(--vh, 1vh) * 6);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: calc(var(--vh, 1vh) * 1.5);
}
