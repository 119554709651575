.card-overlay_root {
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card-overlay_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.card-overlay_card-container {
  animation: card-overlay_card-container-anim 0.5s forwards;
  transform: scale(0);
}

.card-overlay_footer {
  display: flex;
  justify-content: center;
  margin-bottom: calc(var(--vh, 1vh) * 4);
}

@keyframes card-overlay_card-container-anim {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
