.bet-summary_root-container {
  height: calc(var(--vh, 1vh) * 85);
}

.bet-summary_root {
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-height: 75%;
  display: flex;
}

.bet-summary_grid-container {
  margin-top: calc(var(--vh, 1vh) * 5);
  width: 100%;
  justify-content: center;
  display: flex;
}

.bet-summary_grid {
  width: 100%;
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  align-items: center;
  grid-gap: calc(var(--vh, 1vh) * 4) 0;
}

.bet-summary_active-bid {
  width: calc(var(--vh, 1vh) * 4);
  height: calc(var(--vh, 1vh) * 4);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1000;
  bottom: calc(var(--vh, 1vh) * -1.5);
  margin-left: calc(var(--vh, 1vh) * -1.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: calc(var(--vh, 1vh) * 2.5);
  opacity: 0;
  animation: bet-summary_active-bid-anim 0.7s forwards;
}

@keyframes bet-summary_active-bid-anim {
  0% {
    opacity: 0;
    transform: scale(3);
  }
  100% {
    opacity: 1;
  }
}

.bet-summary_active-bid-text {
  margin-right: calc(var(--vh, 1vh) * 0.4);
}

.bet-summary_bid-container {
  justify-content: center;
  width: 100%;
  align-self: flex-end;
  display: flex;
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 12);
}

.bet-summary_draft-container {
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.5s;
  display: flex;
  animation: drafting-set_root-anim 0.7s forwards cubic-bezier(0.49, 0.17, 0.44, 0.84);
  transform: translateX(-100vw);
}

@keyframes drafting-set_root-anim {
  100% {
    transform: translateX(0);
  }
}
