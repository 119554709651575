.racer-select-phase_selection-root {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: calc(var(--vh, 1vh) * 4);
}

.racer-select-phase_selection-container {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  width: calc(var(--vh, 1vh) * 14);
  height: calc(var(--vh, 1vh) * 20);
  display: flex;
  justify-content: center;
  align-items: center;
}

.racer-select-phase_grid {
  margin-top: calc(var(--vh, 1vh) * 5);
}

.racer-select-phase_btn-container {
  margin-top: calc(var(--vh, 1vh) * 3);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-racer-display_stats-container {
  margin-top: calc(var(--vh, 1vh) * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: calc(var(--vh, 1vh) * 4);
}

.select-racer-display_gem-container {
  display: flex;
  margin-right: calc(var(--vh, 1vh) * 4);
}

.select-racer-display_red-gem {
  margin-right: calc(var(--vh, 1vh) * 0.5);
  background-image: url('../assets/heart.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: calc(var(--vh, 1vh) * 4);
  height: calc(var(--vh, 1vh) * 4);
}

.select-racer-display_red-gem-damaged {
  margin-right: calc(var(--vh, 1vh) * 0.5);
  background-image: url('../assets/heartBroken.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: calc(var(--vh, 1vh) * 4);
  height: calc(var(--vh, 1vh) * 4);
}

.select-racer-display_coin {
  margin-right: calc(var(--vh, 1vh) * 0.5);
  background-image: url('../assets/bet/gold.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: calc(var(--vh, 1vh) * 4);
  height: calc(var(--vh, 1vh) * 4);
}

.select-racer-display_gem-text {
}

.select-racer-display_gem-text-dmg {
  color: #dd4532;
}

.select-racer-display_purple-gem {
  margin-right: calc(var(--vh, 1vh) * 0.5);
  background-image: url('../assets/purpleGem.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: calc(var(--vh, 1vh) * 4);
  height: calc(var(--vh, 1vh) * 4);
}

.select-racer-display_cards-container {
  margin-top: calc(var(--vh, 1vh) * 2);
  display: flex;
  align-items: center;
  font-size: calc(var(--vh, 1vh) * 3);
  width: 80vw;
  margin-left: 10vw;
  height: calc(var(--vh, 1vh) * 20);
}

.select-racer-display_items-container {
  width: 100vw;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-racer-display_driver {
  margin-left: calc(var(--vh, 1vh) * 2);
  width: 100%;
  margin-top: calc(var(--vh, 1vh) * -20);
}

.select-racer-display_hat {
  width: 100%;
  margin-top: calc(var(--vh, 1vh) * -8);
  margin-left: calc(var(--vh, 1vh) * 1);
}

.select-racer-display_vehicle {
  margin-left: calc(var(--vh, 1vh) * 5);
  width: 100%;
}

.select-racer-display_button-container {
  display: flex;
  justify-content: space-around;
  bottom: calc(var(--vh, 1vh) * 3);
  position: absolute;
  width: 100%;
}
