@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  background-color: white;
}

body {
  margin: 0;
  font-family: 'Bangers', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  font-family: 'Bangers', cursive;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fill-height {
  height: 100%;
}

.bg-red {
  background-color: red;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.letter-spacing-2 {
  letter-spacing: 0.5em;
}

.text--white {
  color: #ffffff;
}

.text-h3 {
  font-size: 36px;
}

.text-body {
  font-size: 22px;
}

.text-underline {
  text-decoration: underline;
}

.text-strikethrough {
  text-decoration: line-through;
}

.w-100 {
  width: 100%;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-6 {
  margin-top: 24px;
}

.ma-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-5 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ma-1 {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.white--text {
  color: #ffffff !important;
}

.card {
  margin: 0 5px;
  border-radius: 4px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border-width: thin;
  display: block;
  max-width: 100%;
  outline: none;
  text-decoration: none;
  transition-property: box-shadow, opacity;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.card-text {
  padding: 16px;
}

.flex {
  flex: 1;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.white-space-nowrap {
  white-space: nowrap;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-space-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.button-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 50px;
}

.button-word {
  margin: 15px 0;
  padding: 15px 20px;
  /*background: #ffcb89;*/
  background: teal;
  color: #ffffff;
  border: 4px solid #ffffff;
  font-weight: 400;
  font-size: 26px;
  opacity: 0.8;
  border-radius: 5px 20px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.4), 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 1px 5px 0 rgba(0, 0, 0, 0.22);
}

.button-word.paper-button {
  background-size: 100% 100%;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 20px 30px;
  color: #000000;
}

/*.button-word:hover, .button-word:focus {*/
/*    opacity: 1;*/
/*    background: #0003ff;*/
/*    color: #ffffff;*/
/*}*/

.form-control {
  display: flex;
  flex-direction: row;
}

.muted {
  opacity: 0.6;
}

.input-guess {
  display: block;
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  border: 4px solid #ad9eff;
  font-size: 20px;
}

.input-paper,
.input-paper:active,
input-paper:focus {
  background-size: 100% 100%;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 50px 10px;
  font-size: 40px;
  outline: none;
}

.hg-theme-default .hg-button.hg-button-ent {
  background: teal;
  font-weight: bold;
  color: #fff;
}

.hg-theme-default .hg-button.hg-button-clearall {
  background: grey;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 420px) {
  .hg-theme-default .hg-button {
    padding: 35px 5px !important;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(-5px) translateX(-5px);
  }
}

.bounce {
  animation: bounce 1.5s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: translateY(0) scale(1);
  }
  100% {
    transform: translateY(-5px) scale(1.05);
  }
}

.pulse {
  animation: pulse 1.1s infinite alternate;
}
