.board_root {
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-image: url(../assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.board_root-coins {
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-image: url(../assets/backgroundCoins.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.board_root-dark {
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  position: relative;
  z-index: 1;
}

.board_play-again {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--vh, 1vh) * 7);
  color: white;
}
