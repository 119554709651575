.betting-phase-row_root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: filter 0.5s;
}

.betting-phase-row_disabled {
  filter: brightness(50%);
}

.betting-phase-row_driver-bg {
  width: calc(var(--vh, 1vh) * 11);
  height: calc(var(--vh, 1vh) * 9.8);
  background-size: contain;
  background-repeat: no-repeat;
}

.betting-phase-row_driver {
  background-size: contain;
  width: calc(var(--vh, 1vh) * 12);
  height: calc(var(--vh, 1vh) * 12);
  margin-top: calc(var(--vh, 1vh) * -1);
  margin-left: calc(var(--vh, 1vh) * -1);
}

.betting-phase-row_left {
  font-size: calc(var(--vh, 1vh) * 4.5);
  color: white;
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  margin-left: calc(var(--vh, 1vh) * 3);
}

.betting-phase-row_chip {
  width: calc(var(--vh, 1vh) * 7);
  height: calc(var(--vh, 1vh) * 7);
  background-image: url('../assets/scoring/bettingChips.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: calc(var(--vh, 1vh) * -8);
  top: calc(var(--vh, 1vh) * 2);
  animation: chip-enter 0.5s;
}

@keyframes chip-enter {
  0% {
    transform: scale(2);
    opacity: 0;
  }
}

.betting-phase-row_right {
  color: white;
  font-size: calc(var(--vh, 1vh) * 4);
  display: flex;
  z-index: 1;
  justify-content: center;
  position: relative;
}

.betting-phase-row_coin {
  background-image: url('../assets/bet/gold.png');
  width: calc(var(--vh, 1vh) * 3);
  height: calc(var(--vh, 1vh) * 3);
  background-size: contain;
  background-repeat: no-repeat;
}

.betting-phase-row_right-container-overlay {
  margin-top: calc(var(--vh, 1vh) * 3);
}

.betting-phase-row_right-container {
  margin-right: calc(var(--vh, 1vh) * 5.5);
}
