.racing-phase-move_container-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
}

.racing-phase-move_background {
  position: absolute;
  top: 0;
  bottom: 0;
  background-image: url(../assets/action-bg.png);
  mix-blend-mode: luminosity;
  height: calc(var(--vh, 1vh) * 100);
  width: 130vw;
  background-position-x: center;
  margin-left: -30vw;
}
