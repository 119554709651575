.scoring-phase_root {
  height: calc(var(--vh, 1vh) * 85);
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.scoring-phase_score-title {
  margin-top: calc(var(--vh, 1vh) * 3);
  font-size: calc(var(--vh, 1vh) * 3);
}

.scoring-phase_score {
  margin-top: calc(var(--vh, 1vh) * 1);
  font-size: calc(var(--vh, 1vh) * 6);
  display: flex;
}

.scoring-phase_coin {
  background-image: url('../assets/bet/gold.png');
  width: calc(var(--vh, 1vh) * 6);
  height: calc(var(--vh, 1vh) * 6);
  margin-right: calc(var(--vh, 1vh) * 1);
  background-size: contain;
  background-repeat: no-repeat;
}

.scoring-phase_coin-sm {
  background-image: url('../assets/bet/gold.png');
  width: calc(var(--vh, 1vh) * 3);
  height: calc(var(--vh, 1vh) * 3);
  margin-right: calc(var(--vh, 1vh) * 1);
  background-size: contain;
  background-repeat: no-repeat;
}

.scoring-phase_body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
}

.scoring-phase_number-container {
  background-image: url('../assets/scoring/speechBubble.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: calc(var(--vh, 1vh) * 15);
  height: calc(var(--vh, 1vh) * 15);
  background-position: center;
  display: flex;
  justify-content: center;
  color: black;
  font-size: calc(var(--vh, 1vh) * 3);
  padding-top: calc(var(--vh, 1vh) * 9);
  animation: scoring-phase_number-container-anim 0.5s forwards;
  transform: scale(0);
}

@keyframes scoring-phase_number-container-anim {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes left-enter-anim {
  0% {
    transform: translateX(-50vw);
  }
  50% {
    transform: translateX(5vw) rotate(5deg);
  }
  100% {
    transform: translateX(0);
  }
}

.scoring-phase_cards-container {
  display: flex;
  animation: left-enter-anim 0.8s 0.6s forwards;
  transform: translateX(-50vw);
}

.scoring-phase_card {
  margin-right: calc(var(--vh, 1vh) * -5);
}

.scoring-phase_card-first {
  transform: rotate(-4deg);
}

.scoring-phase_card-last {
  transform: rotate(4deg);
}

.scoring-phase_chips {
  background-image: url('../assets/scoring/bettingChips.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: calc(var(--vh, 1vh) * 12);
  height: calc(var(--vh, 1vh) * 12);
  background-position: center;
  animation: left-enter-anim 0.8s;
}

.scoring-phase_medal {
  width: calc(var(--vh, 1vh) * 10);
  height: calc(var(--vh, 1vh) * 10);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  font-size: calc(var(--vh, 1vh) * 4);
  padding-top: calc(var(--vh, 1vh) * 5);
  animation: left-enter-anim 0.8s 1.2s forwards;
  transform: translateX(-50vw);
}

.scoring-phase_medal-gold {
  background-image: url('../assets/bet/medalGold.png');
}

.scoring-phase_medal-bronze {
  background-image: url('../assets/bet/medalBronze.png');
}

.scoring-phase_medal-metal {
  background-image: url('../assets/bet/medalMetal.png');
}

.scoring-phase_medal-broken {
  background-image: url('../assets/bet/medalBroken.png');
}
